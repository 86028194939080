@import 'variabels';

html {
  -webkit-font-smoothing: unset !important;
  -moz-osx-font-smoothing: unset !important;
  background-color: white;
  font-size: 62.5%;
}
body {
  position: relative;
  font: 300 1.7rem / 1.65 'Roboto', Arial, Tahoma, Geneva, sans-serif !important;
  font-family: 'Roboto', Arial, Tahoma, Geneva, sans-serif !important;
  letter-spacing: normal !important;
  min-height: calc(var(--vh, 1vh) * 100);
}
html {
  height: calc(var(--vh, 1vh) * 100);
}
#root {
  background: $color-secondary;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: calc(var(--vh, 1vh) * 100);
}

@media (max-width: 1024px) {
  body {
    &.is-share-this {
      #root {
        min-height: calc((var(--vh, 1vh) * 100) - 48px);
      }
    }
    .chat-wrapper {
      bottom: 4.8rem;
      z-index: 1001;
    }
  }
}
h1 {
  line-height: 1.2;
  font-size: 4.5rem;
  font-weight: 300;
  margin: 0 0 2rem;
}
h2,
h3 {
  font-weight: 300;
  line-height: 1.2;
  margin: 0 0 1.5rem;
}
h2 {
  font-size: 3.2rem;
}
iframe {
  border-width: 0;
}
