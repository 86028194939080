@import 'variabels';

.image-uploader {
  .MuiSvgIcon-colorSecondary,
  .MuiButton-textSecondary {
    color: $color-secondary
  }
}

.image-uploader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .image-uploader {
    width: auto;
    display: inline-flex;
    border-radius: 100%;
  }
}

.text-label {
  font-size: 1.3rem;
  color: #6d7278;
}

p.label {
  color: $color-label;
  padding: 0;
  margin: 0;
}

.app-list-style {
  list-style: none;
  margin: 0;
  padding: 0;

  > li {
    padding: .5rem 0;
    display: flex;
    align-items: center;

    &:before {
      content: '';
      display: block;
      height: .6rem;
      width: .6rem;
      background-color: $color-secondary;
      border-radius: 50%;
      margin: 0 1rem;
    }
  }
}

.filter-panel {
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 2rem;

  &__fields {
    &_auto {
      display: grid;
      grid-column-gap: 2rem;
      grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
      @media (max-width: map-get($breakpoints, 'lg') + px) {

      }
    }
  }

  &__controls {
    display: flex;
    align-items: center;
    justify-content: center;

    .app-button-secondary {
      &.app-button-secondary_variant_text {
        font-weight: 400;
        font-size: 1.4rem;
        text-transform: unset;
      }
    }
  }
}

@media (max-width: map-get($breakpoints, 'lg') + px) {
  .filter-panel {
    grid-template-columns: 1fr;

    &__fields {
    }

    &__controls {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.image-dialog {
  canvas {
    max-width: 100% !important;
    height: auto !important;
  }
}

.text-ellipsis {
  white-space: normal;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 100%;
  display: inline-block;
}

.field-blue {
  background: #f3f6f9;
  margin: 0 -1rem;
  padding: 0 1rem;
  border-radius: 0.5rem;
}

.image-uploader-wrapper {
  &.alternative {
    margin-bottom: 1.5rem;

    .image-uploader {
      display: flex;
      align-items: center;
      justify-content: center;

      .thumb {
        margin: 0;
        border-radius: 0;
      }
    }

    .app-image-uploader-no-data,
    .image-uploader-wrapper .thumb {
      border-radius: 0;
    }

    .controls {
      position: absolute;
      bottom: 0px;
      margin: 0;
    }
  }
}

.app-tabs {
  .MuiTabs-indicator {
    bottom: unset;
    top: 0;
  }

  &__tab-item {
    &.MuiTab-root {
      text-transform: capitalize;
      font-weight: normal;
      font-size: 19px;
      line-height: 22px;
      color: $color-secondary;
      background-color: #EEF2F4;

      &.Mui-selected {
        background-color: white;
        color: black;
      }
    }
  }
}

.green {
  color: $color-primary;
}


// MuiPickersBasePicker Landscape view
.MuiPickersBasePicker-containerLandscape {
  flex-direction: column;
}

.MuiPickersToolbar-toolbarLandscape {
  max-width: inherit;
  height: 100px;
  justify-content: center;
  padding: 0;
}

.MuiPickersTimePickerToolbar-hourMinuteLabel {
  .MuiTypography-root {
    font-size: 6rem;
  }
}

.viewMore {
  text-align: center;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    height: 1px;
    left: 0;
    right: 0;
    background-color: $color-divider;
  }


  button {
    background-color: #fff;
    font-weight: 600;
    border: solid $color-divider 1px;
    color: $color-secondary;
    position: relative;
    z-index: 2;
    min-width: 12rem;

    &:hover {
      background-color: #e8e8e8;
    }
  }

}