@import "src/styles/variabels";
.app-footer{
  background: $bg-secondary-vertical;
  color: $color-border;
  padding: 1rem 0 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  &_alternative {
    padding: 1rem 0;
    .app-footer{
      &__inner{
        justify-content: flex-start;
        display: grid;
        grid-template-columns: 24rem auto;
        grid-column-gap: 4rem;
        grid-template-areas:
                'logo terms'
                'logo cop';
      }
      &__logo{
        grid-area: logo;
        position: relative;
        &:after{
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -2rem;
          height: 4rem;
          width: 1px;
          background-color: rgba(#CFDCE7, .3);
        }
      }
      &__cop{
        grid-area: cop;
      }
      &__terms{
        grid-area: terms;
      }
      &__item{
        padding-bottom: 0;
      }
    }

    .footer-logo{
      align-items: center;
      a {
        margin-right: .8rem;
      }
    }
  }

  &__item{
    padding-bottom: 1rem;
    height: 100%;
    width: 100%;
    order: 1;
  }
  &__cop{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 1.3rem;
  }
  &__terms{
    text-align: center;
  }
  &__divider{
    height: 2rem !important;
    background-color: $color-border !important;
    margin: 0 1rem;
  }
  &__controls{
    margin: 0 -1rem;
    height: 100%;
    display: inline-flex;
    align-items: center;
  }
  &__link{
    color: $color-white !important;
    font-size: 1.5rem;
    text-transform: capitalize;
    font-weight: 500;
    padding: 0 1rem;
    &:hover{
      color: $color-white;
      cursor: pointer;
    }
  }
  &__inner{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: map-get($breakpoints, 'lg') + px) {
    &_alternative {
      padding: 2rem 0;
    }
  }
  @media (max-width: map-get($breakpoints, 'md') + px) {
    &__inner{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
    }
    &__cop{
      order: 3;
    }

    &_alternative {
      .app-footer{
        &__inner{
          grid-template-columns: auto;
          grid-template-areas:
                'logo'
                'terms'
                'cop';
        }
        &__logo{
          &:after{
            content: none;
          }
        }

        &__terms{
          text-align: left;
          margin-top: 2rem;
        }
        &__cop{
          justify-content: flex-start;
        }
      }
      .footer-logo{
        flex-direction: row;
        max-width: 30rem;
        &__img{
          height: auto;
          width: auto;
        }
      }
    }
  }
}
.footer-logo{
  display: flex;
  justify-content: flex-start;
  &__title{
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__link{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media (max-width: map-get($breakpoints, 'md') + px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &__title{
      font-size: 1.5rem;
    }
    &__img{
      height: 2.5rem;
      width: 2.5rem;
    }
  }
}
