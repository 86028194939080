$color-primary: #5fb800;
$color-primary-light: #6dd400;

$color-orange: #e55c1a;
$color-orange-light: #ff681f;

$color-error: #ea6767;
$color-pur: #9650cd;
$color-blue-light: #729eca;

$color-secondary: #36587a;
$color-secondary-light: #4f769c;

$bg-secondary: linear-gradient(#5b84ae 0%, #36587a 100%);
$bg-secondary-vertical: linear-gradient(270deg, #36587a 0, #5d86b0 50%, #36587a);

$color-white: #fff;
$color-black: #000;
$color-divider: #eef2f4;

$color-default: #434343;
$color-border: #cfdce7;

$color-label: #606266;
$color-label-secondary: #aeb5c1;

$header-height: 7rem;
$header-height-mobile: 6.4rem;
$footer-height: 6rem;
$footer-height-mobile: 6rem;

$base-padding: 1.6rem;
$breakpoints: (
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
);

:export {
  @each $key, $value in $breakpoints {
    #{unquote($key)}: $value;
  }
}

:export {
  colorPrimary: $color-primary;
  colorOrange: $color-orange;
  colorSecondary: $color-secondary;
  colorDefault: $color-default;
  colorBorder: $color-border;
  colorPur: $color-pur;
  colorWhite: $color-white;
  colorBlueLight: $color-blue-light;
  colorDivider: $color-divider;
  colorLabelSecondary: $color-label-secondary;
  bgSecondary: $bg-secondary;
}

@mixin svg-blue {
  filter: invert(30%) sepia(38%) saturate(589%) hue-rotate(169deg) brightness(96%) contrast(94%);
}

@mixin svg-white {
  filter: invert(98%) sepia(6%) saturate(0%) hue-rotate(310deg) brightness(116%) contrast(100%);
}

// @media (max-width: map-get($breakpoints, 'lg') - 1 + px) {}

@mixin xs {
  @media (min-width: map-get($breakpoints, 'xs') + px) {
    @content;
  }
}

@mixin sm {
  @media (min-width: map-get($breakpoints, 'sm') + px) {
    @content;
  }
}

@mixin md {
  @media (min-width: map-get($breakpoints, 'md') + px) {
    @content;
  }
}

@mixin lg {
  @media (min-width: map-get($breakpoints, 'lg') + px) {
    @content;
  }
}

@mixin xl {
  @media (min-width: map-get($breakpoints, 'xl') + px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: map-get($breakpoints, 'xxl') + px) {
    @content;
  }
}
