@import 'src/styles/variabels';
.MuiFormLabel-asterisk {
  color: #f56c6c;
}
//.MuiInputBase-input{
//  font-style: normal !important;
//  font-weight: normal !important;
//  font-size: 13px !important;
//  line-height: 15px !important;
//}
.MuiAutocomplete-popper {
  width: auto !important;
}
.MuiAutocomplete-listbox {
  max-height: unset !important;
}
.MuiAutocomplete-option {
  font-size: 1.3rem;
}
.MuiAutocomplete-option[aria-selected='true'] {
  color: $color-secondary !important;
  background-color: rgba($color-secondary, 0.05) !important;
}
.MuiAutocomplete-option[data-focus='true'] {
  background-color: rgba($color-secondary, 0.05) !important;
}
.MuiAutocomplete-inputRoot[class*='MuiInput-root'] {
  padding-bottom: 0 !important;
  position: relative;
  .MuiCircularProgress-root {
    position: absolute;
    left: 15px;
  }
}
//.MuiAutocomplete-inputRoot[class*='MuiInput-root'] .MuiAutocomplete-input:first-child {
//  padding-top: 8px !important;
//  padding-bottom: 8px !important;
//}
