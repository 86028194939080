$tl-border-color: #EEF2F4;
.table-list{
  &__row{
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    min-height: 7rem;
    border-bottom: 1px solid $tl-border-color;
    position: relative;
    &_head{
      border-bottom-width: 2px;
      min-height: 6rem;
      .table-list{
        &__cell{
          text-transform: uppercase;
          font-weight: bold;
          font-size: 12px;
          line-height: 14px;
          color: #6D7278;
        }
      }
    }
  }
  &__cell{
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 15px;
    padding: .5rem 0;
    flex-wrap: wrap;
    &_center{
      justify-content: center;
    }
    &_end{
      justify-content: flex-end;
    }
  }
}
.country-cell{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: 300;
  font-size: 13px;
  line-height: 18px;
  text-align: right;
  color: rgba(0, 0, 0, 0.54);
  span {}
  img{
    max-width: 2rem;
    max-height: 2rem;
    display: inline-block;
    margin: 0 0 0 1rem;
  }
}
.logo-cell{
  display: flex;
  align-items: center;
  &_default {
    .logo-cell{
      &__img-wrap{
        border-radius: 50%;
        border: 1px solid $tl-border-color;
      }
    }
  }
  &__title{
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
  }
  &__img-wrap{
    width: 4.8rem;
    height: 4.8rem;
    margin-right: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  img{
    max-width: 4.8rem;
    max-height: 4.8rem;
    height: auto;
    width: auto;
  }
}
