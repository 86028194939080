$border_color: rgba(0, 0, 0, .12);
$border_color-error: #f44336;
.image-uploader-wrapper {
  position: relative;
  margin-bottom: 1.5rem;
  font-size: 0;
  .image-uploader {
    position: relative;
    overflow: hidden;
    width: 100%;
    border: 1px solid $border_color;
    display: flex;
    align-items: center;
    justify-content: center;
    &.error {
      border-color: $border_color-error;
    }
  }
  .no-image {
    display: block;
    margin: auto;
    position: relative;
    .MuiSvgIcon-root {
      font-size: 10rem;
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 50%;
      height: 50%;
      margin: -25% 0 0 -25%;
      color: $border_color;
    }
  }
  .thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
    }
  }
  input[type=file] {
    position: absolute;
    top: -1000px;
  }
  &.default {
    .no-image {
      .MuiSvgIcon-root {
        top: 35%;
      }
    }
    .controls {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      background-color: rgba(255, 255, 255, .2);
      box-shadow: -5px 0 5px 3px rgba(0, 0, 0, 0.12);
      button {
        margin: .8rem .5rem;
      }
    }
  }
  &.alternative {
    margin-bottom: 0;
    .image-uploader {
      border: none;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      .thumb,
      .no-image {
        border: 1px solid $border_color;
        box-sizing: content-box;
        margin: 0 1.5rem 1.5rem 0;
      }
      &.error {
        .thumb,
        .no-image {
          border-color: $border_color-error;
        }
      }
    }
    .controls {
      margin-bottom: 1.5rem;
      button {
        margin: 0 .5rem 0 0
      }
      p {
        margin: 0;
      }
    }
  }
  &.label {
    .image-uploader {
      margin-top: 1rem;
    }
  }
  p.error {
    color: $border_color-error;
    font-size: 1.2rem;
    margin: .8rem 0 0;
    min-height: 1em;
    font-weight: 400;
    line-height: 1em;
    letter-spacing: 0.03333em;
  }
  p.note {
    padding: .8rem 0 .8rem 3rem;
    font-size: 1.2rem;
    min-height: 1em;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: 0.03333em;
    position: relative;
    span {
      white-space: nowrap;
      display: block;
    }
    svg {
      position: absolute;
      left: 0;
      top: .5rem;
      font-size: 2.2rem;
    }
  }
}
.image-dialog {
  .MuiDialog-paper {
    min-width: 25rem;
    canvas {
      display: block;
      margin: auto auto 2rem;
    }
    .MuiTypography-body1 {
      margin: 1rem 0 0;
    }
    .MuiSlider-root {
      margin: 0 .5rem;
      width: calc(100% - 1rem);
    }
  }
}
