@import "./variabels";

body {
  @media (max-width: map-get($breakpoints, 'lg') + px) {
    &.is-chat-full {
      overflow: hidden;

      .chat-wrapper {
        bottom: 0;
        z-index: 1005;

        .chat {
          margin: 0;
          right: 0;
        }
      }

      .chat {
        margin: 0;

        &.open.full {
          .chat__messages {
            height: auto;
          }
        }
      }
    }
    .chat-wrapper {
      width: 100vw;
      left: 0;

      .chat {
        margin: 0;
        right: 1rem;
        position: absolute;

        &.open {
          &.full {
            height: calc(var(--vh, 1vh) * 100);
            width: 100vw;
            left: 0;
          }
        }
      }
    }
  }
  @media (max-width: map-get($breakpoints, 'sm') + px) {
    &.is-chat-open {
      overflow: hidden;
      .chat-wrapper{
        bottom: 0;
        z-index: 1005;
        .conversations{
          grid-template-rows: 4.8rem auto 1fr;
          &__scroll{
            max-height: unset;
          }
        }
        .conversations,
        .chat{
          height: calc(var(--vh, 1vh) * 100);
          width: 100vw;
          left: 0;
          right: 0;
          margin: 0;
        }
        .chat__messages{
          height: auto;
        }
      }
    }
  }
}

