@import './variabels';
.container{
  width: 100%;
  padding-right: $base-padding;
  padding-left: $base-padding;
  margin-right: auto;
  margin-left: auto;
  @media (min-width: map-get($breakpoints, 'sm') + px) {
    max-width: map-get($breakpoints, 'sm') - (36) + px
  }
  @media (min-width: map-get($breakpoints, 'md') + px) {
    max-width: map-get($breakpoints, 'md') - (48) + px
  }
  @media (min-width: map-get($breakpoints, 'lg') + px) {
    max-width: map-get($breakpoints, 'lg') - (32) + px
  }
  @media (min-width: map-get($breakpoints, 'xl') + px) {
    max-width: map-get($breakpoints, 'xl') - (60) + px
  }
}
