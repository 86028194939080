.form-edit-controls {
  .right-side {
    button {
      margin: 0 0 0 1rem;
    }
  }
}
.MuiAutocomplete-root {
  .MuiAutocomplete-inputRoot {
    &.MuiInputBase-adornedStart {
      padding-left: 2.5rem;
      .MuiInputAdornment-positionStart {
        position: absolute;
        left: 0;
      }
    }
    .MuiAutocomplete-input {
      min-width: 4rem !important;
    }
  }
}
